p {
    margin-bottom: 10px;
}

a {
    text-decoration: underline !important;
    color: grey !important;
}

hr {
    border-top-width: 3px;
    margin: 10px 0
}

blockquote {
    border-left: 2px solid #ccc;
    padding-left: 10px;
    margin: 20px 0;
    font-style: italic;
}

h1 {
    font-size: 32px;
    font-weight: bold;
    margin-top: 32px;
    margin-bottom: 16px;
    color: #333;
  }

h2 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 24px;
    margin-bottom: 12px;
    color: #333;
}

h3 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #333;
  }