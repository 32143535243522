 :root, [data-amplify-theme] {
  --amplify-colors-purple-100: #623D80;
  --amplify-colors-purple-90: #794C9D;
  --amplify-colors-purple-80: #9050b6;
  --amplify-colors-brand-primary-100: var(--amplify-colors-purple-100);
  --amplify-colors-brand-primary-90: var(--amplify-colors-purple-90);
  --amplify-colors-brand-primary-80: var(--amplify-colors-purple-80);
  --amplify-colors-brand-primary-60: var(--amplify-colors-purple-60);
  --amplify-colors-brand-primary-40: var(--amplify-colors-purple-40);
  --amplify-colors-brand-primary-20: var(--amplify-colors-purple-20);
  --amplify-colors-brand-primary-10: var(--amplify-colors-purple-10);
  --amplify-components-button-color: var(--amplify-colors-font-primary);
}
[data-amplify-authenticator] {
  display: grid;
}
[data-amplify-authenticator][data-variation=modal] {
  overflow-y: auto;
  width: var(--amplify-components-authenticator-modal-width);
  height: var(--amplify-components-authenticator-modal-height);
  background: var(--amplify-colors-purple-80) url('/src/assets/img/cover-auth.png') no-repeat right center/* todo use image with logo */;
  background-size: 50%;
  position: fixed;
  top: var(--amplify-components-authenticator-modal-top);
  left: var(--amplify-components-authenticator-modal-left);
  /* Having a z-index at least "wins" by default */
  z-index: 1;
}
[data-amplify-authenticator] [data-amplify-container] {
  place-self: start;
}
@media (min-width: 30rem) {
[data-amplify-authenticator] [data-amplify-container] {
    width: 50%;
}
}
[data-amplify-authenticator] [data-amplify-router] {
  background-color: var(--amplify-components-authenticator-router-background-color);
  justify-content: center;
  min-height: 100vh;
  align-items: center;
  display: flex;
  border: none;
  box-shadow: none;
}